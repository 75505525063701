<script>
import storage from '@/lib/storage';

export default {
  mounted() {
    storage.removeItem('access_token');
    storage.removeItem('user');
    // this.$store.dispatch('clearUserData');
    if (this.$route.query.redirect_url) {
      // ToDo maybe we should hardcore redirect url
      window.location.href = this.$route.query.redirect_url;
    }
  },
};
</script>
